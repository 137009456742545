import * as lazyWelcomeImports from "./general/welcome/welcomeImport";
import * as lazyNotificationImports from "./general/notification/notificationImport";
import * as lazyChartOfAccountImports from "./accounting/chartOfAccounts/chartOfAccountsImport";
import * as lazyVouchersImports from "./accounting/vouchers/vouchersImport";
import * as lazyVoucherDetailsImports from "./accounting/vouchers/voucherDetailsImport";
import * as lazyVoucherAddImports from "./accounting/vouchers/voucherAddImport";
import * as lazyJournalImports from "./accounting/journal/journalImport";
import * as lazyTrailbalanceImports from "./accounting/trailbalance/trailbalanceImport";
import * as lazyLedgerImports from "./accounting/ledger/ledgerImport";
import * as lazyAccountPayrollImports from "./accounting/accountPayroll/accountPayrollImport";
import * as lazyPendingDetailsImports from "./accounting/accountPayroll/pendingDetails/pendingDetailsImport";
import * as lazyProcessingDetailsImports from "./accounting/accountPayroll/processingDetails/processingDetailsImport";
import * as lazySettledDetailsImports from "./accounting/accountPayroll/settledDetails/settledDetailsImport";
import * as lazyExchangeRateImports from "./accounting/exchangeRate/exchangeRateImport";
import * as lazyAccountingRequestImports from "./accounting/requests/requestsImport";
import * as lazyAccountingRequestDetailsImports from "./accounting/requests/requestsDetailsImport";
import * as lazyBalanceSheetImports from "./accounting/financialReports/balanceSheet/balanceSheetImport";
import * as lazyIncomeExpenseReportImports from "./accounting/financialReports/incomeExpenseReport/incomeExpenseReportImport";
import * as lazyNotesImports from "./accounting/financialReports/notes/notesImport";
import * as lazyExchangeGainLossImports from "./accounting/configuration/exchangeGainLoss/exchangeGainLossImport";
import * as lazyFinancialYearImports from "./accounting/configuration/settingFinancialYear/financialYearImport";
import * as lazyOfficeImports from "./accounting/configuration/office/officeImport";
import * as lazyCurrencyImports from "./accounting/configuration/currency/currencyImport";
import * as lazyBankImports from "./accounting/configuration/bank/bankImport";
import * as lazyBankAccountImports from "./accounting/configuration/bankAccount/bankAccountImport";
import * as lazySupplierImports from "./accounting/configuration/supplier/supplierImport";
import * as lazyVoucherSignatureImports from "./accounting/configuration/voucherSignature/voucherSignatureImport";
import * as lazyOpportunityImports from "./projectManagementUnit/opportunity/opportunityImport";
import * as lazyAddOpportunityImports from "./projectManagementUnit/opportunity/addOpportunityImport";
import * as lazyEditOpportunityImports from "./projectManagementUnit/opportunity/editOpportunityImport";
import * as lazyDetailsOpportunityImports from "./projectManagementUnit/opportunity/detailsOpportunityImport";
import * as lazyProjectManagementImports from "./projectManagementUnit/projectManagement/projectManagementImport";
import * as lazyProjectDetailsImports from "./projectManagementUnit/projectManagement/projectDetailsImport";
import * as lazySectorImports from "./projectManagementUnit/configuration/sector/sectorImport";
import * as lazyDonorsImports from "./projectManagementUnit/configuration/donors/donorsImport";
import * as lazyPeopleTargetedCategoriesImports from "./projectManagementUnit/configuration/peopleTargetedCategories/peopleTargetedCategoriesImport";
import * as lazyUserImports from "./usersManagement/users/usersImport";
import * as lazyUserRolesImports from "./usersManagement/userRoles/userRolesImport";
import * as lazyProspectiveEmployeeImports from "./humanResources/prospectiveEmployee/prosEmployeeImport";
import * as lazyProspectiveEmployeeAddImports from "./humanResources/prospectiveEmployee/prosEmployeeAddImport";
import * as lazyProspectiveEmployeeEditImports from "./humanResources/prospectiveEmployee/prosEmployeeEditImport";
import * as lazyProspectiveEmployeeAddContractImports from "./humanResources/prospectiveEmployee/prosEmployeeAddContractImport";
import * as lazyEmployeeImports from "./humanResources/employee/employeeImport";
import * as lazyEmployeeEditImports from "./humanResources/employee/employeeEditImport";
import * as lazyContractAddImports from "./humanResources/employee/contract/contractAddImport";
import * as lazyContractDetailsImports from "./humanResources/employee/contract/contractDetailsImport";
import * as lazyContractEditImports from "./humanResources/employee/contract/contractEditImport";
import * as lazyTerminateContractImports from "./humanResources/employee/contract/terminateContractImport";
import * as lazyAttendanceImports from "./humanResources/attendance/attendanceImport";
import * as lazyDetailsPendinImports from "./humanResources/attendance/pending/detailsPendingImport";
import * as lazyDetailsValidatedImports from "./humanResources/attendance/validated/detailsValidatedImport";
import * as lazyPayrollImports from "./humanResources/payroll/payrollImport";
import * as lazyPayrollPendingImports from "./humanResources/payroll/pending/payrollPendingImport";
import * as lazyPayrollGeneratedImports from "./humanResources/payroll/generated/payrollGeneratedImport";
import * as lazyPayrollExchangeRatesImports from "./humanResources/payrollExchangeRates/payrollExchangeRatesImport";
import * as lazyRecruitmentImports from "./humanResources/recruitment/recruitmentImport";
import * as lazyRecruitmentAddImports from "./humanResources/recruitment/recruitmentIAddImport";
import * as lazyRecruitmentDetailsImports from "./humanResources/recruitment/recruitmentDetailsImport";
import * as lazyRecruitmentEditImports from "./humanResources/recruitment/recruitmentIEditImport";
import * as lazyInterviewFormImports from "./humanResources/recruitment/interviewFormImport";
import * as lazyDegreeImports from "./humanResources/configuration/degree/degreeImport";
import * as lazyDesignationImports from "./humanResources/configuration/designation/designationImport";
import * as lazyDocumentImports from "./humanResources/configuration/document/documentImport";
import * as lazyEmployeeGradeImports from "./humanResources/configuration/employeeGrade/employeeGradeImport";
import * as lazyProfessionImports from "./humanResources/configuration/profession/professionImport";
import * as lazyFieldOfStudyImports from "./humanResources/configuration/fieldOfStudy/fieldOfStudyImport";
import * as lazyLeaveImports from "./humanResources/configuration/leave/leaveImport";
import * as lazyWorkingHoursImports from "./humanResources/configuration/workingHours/workingHoursImport";
import * as lazyCategoryImports from "./humanResources/configuration/category/categoryImport";
import * as lazyEmploymentTypeImports from "./humanResources/configuration/employmentType/employmentTypeImport";
import * as lazyRequestsImports from "./procurement/requests/requestsImport";
import * as lazyRequestsAddImports from "./procurement/requests/requestsAddImport";
import * as lazyRequestsDetailsImports from "./procurement/requests/requestsDetailsImport";
import * as lazyVendorsImports from "./procurement/vendors/vendorsImport";
import * as lazyVendorsAddImports from "./procurement/vendors/vendorsAddImport";
import * as lazyVendorsDetailsImports from "./procurement/vendors/vendorsDetailsImport";
import * as lazyDocumentTypeImports from "./procurement/configuration/documentType/documentTypeImport";
import * as lazVendorsCategoryImports from "./procurement/configuration/vendorsCategory/vendorsCategoryImport";
import * as lazyMasterStoreItemImports from "./inventory/consumableItems/masterStoreItem/masterStoreItemImport";
import * as lazyConsumableGRNImports from "./inventory/consumableItems/grn/grnImport";
import * as lazyConsumableAddGRNImports from "./inventory/consumableItems/grn/grnAddImport";
import * as lazyConsumableGRNDetailsImports from "./inventory/consumableItems/grn/grnDetailsImport";
import * as lazyStoreItemsImports from "./inventory/consumableItems/storeItems/storeItemsImport";
import * as lazyStoreItemsDetailsImports from "./inventory/consumableItems/storeItems/storeItemsDetailsImport";
import * as lazyStoreItemsDetailsNonExistenceImports from "./inventory/expendableItem/storeItems/storeItemsDetailsImportNonExistence";
import * as lazyExpendableItemMasterStoreItemImports from "./inventory/expendableItem/masterStoreItem/masterStoreItemImport";
import * as lazyExpendableStoreItemNamesImports from "./inventory/expendableItem/storeItemNames/storeItemNamesImport";
import * as lazyExpendableAddGRNImports from "./inventory/expendableItem/grn/grnAddImport";
import * as lazyExpendableGRNImports from "./inventory/expendableItem/grn/grnImport";
import * as lazyExpendableGRNDetailsImports from "./inventory/expendableItem/grn/grnDetailsImport";
import * as lazyExpendableItemStoreItemImports from "./inventory/expendableItem/storeItems/storeItemsImport";
import * as lazyExpendableStoreItemsDetailsImports from "./inventory/expendableItem/storeItems/storeItemsDetailsImport";
import * as lazyExpendableStoreItemsAddImports from "./inventory/expendableItem/storeItems/storeItemsAddImport";
import * as lazyNonExpendableItemMasterStoreItemImports from "./inventory/nonExpendableItem/masterStoreItem/masterStoreItemImport";
import * as lazyNonExpendableStoreItemNamesImports from "./inventory/nonExpendableItem/storeItemNames/storeItemNamesImport";
import * as lazyNonExpendableGRNAddImports from "./inventory/nonExpendableItem/grn/grnAddImport";
import * as lazyNonExpendableGRNImports from "./inventory/nonExpendableItem/grn/grnImport";
import * as lazyNonExpendableGRNDetailsImports from "./inventory/nonExpendableItem/grn/grnDetailsImport";
import * as lazyNonExpendableItemStoreItemImports from "./inventory/nonExpendableItem/storeItems/storeItemsImport";
import * as lazyNonExpendableStoreItemsAddImports from "./inventory/nonExpendableItem/storeItems/storeItemsAddImport";
import * as lazyNonExpendableStoreItemsDetailsImports from "./inventory/nonExpendableItem/storeItems/storeItemsDetailsImport";
import * as lazyEmployeesIssuanceRegisterImports from "./inventory/employeesIssuanceRegister/employeesIssuanceRegisterImport";
import * as lazyInventoryRequest from "./inventory/requests/requestsImport";
import * as lazyInventoryRequestDetails from "./inventory/requests/requestsDetailsImport";
import * as lazyAssetTypeImports from "./inventory/configuration/assetType/assetTypeImport";
import * as lazyDepreciationImports from "./inventory/configuration/depreciation/depreciationImport";
import * as lazyReceiptTypeImports from "./inventory/configuration/receiptType/receiptTypeImport";
import * as lazyStatusImports from "./inventory/configuration/status/statusImport";
import * as lazyLocationImports from "./inventory/configuration/location/locationImport";
import * as lazyUnitImports from "./inventory/configuration/unit/unitImport";
import * as lazySupplyRawMaterialsImports from "./supplyChain/rawmaterials/supplyRawMaterialsImport";
import * as lazySupplyRawMaterialsAddImports from "./supplyChain/rawmaterials/supplyRawMaterialsAddImport";
import * as lazySupplyRawMaterialsDetailsImports from "./supplyChain/rawmaterials/supplyRawMaterialsDetailsImport";
import * as lazySupplyShippmentsImports from "./supplyChain/shipments/supplyShippmentsImport";
import * as lazySupplyShippmentsDetailsImports from "./supplyChain/shipments/supplyShippmentsDetailsImport";
import * as lazyItemsImports from "./supplyChain/configuration/items/itemsImport";
import * as lazyItemsAddImports from "./supplyChain/configuration/items/itemsAddImport";
import * as lazyItemsEditImports from "./supplyChain/configuration/items/itemsEditImport";
import * as lazyGradeImports from "./supplyChain/configuration/grade/gradeImport";
import * as lazyGradeAddImports from "./supplyChain/configuration/grade/gradeAddImport";
import * as lazyGradeEditImports from "./supplyChain/configuration/grade/gradeEditImport";
import * as lazySupplierChainImports from "./supplyChain/configuration/supplier/supplierImport";
import * as lazySupplierChainAddImports from "./supplyChain/configuration/supplier/supplierAddImport";
import * as lazySupplierChainEditImports from "./supplyChain/configuration/supplier/supplierIEditmport";
import * as lazyItemQrCodeImports from "./supplyChain/configuration/itemQrCode/itemQrCodeImport";
import * as lazyItemAddQrCodeImports from "./supplyChain/configuration/itemQrCode/itemAddQrCodeImport";
import * as lazyItemEditQrCodeImports from "./supplyChain/configuration/itemQrCode/itemEditQrCodeImport";
import * as lazyProductDesignImports from "./supplyChain/configuration/productDesign/productDesignImport";
import * as lazyProductDesignAddImports from "./supplyChain/configuration/productDesign/productDesignAddImport";
import * as lazyProductDesignEditImports from "./supplyChain/configuration/productDesign/productDesignEditImport";
import * as lazyLanguagesImports from "./systemConfiguration/languages/languagesImport";
import * as lazyLanguagesAddImports from "./systemConfiguration/languages/languagesAddImport";
import * as lazyLanguagesEditImports from "./systemConfiguration/languages/languagesEditImport";
import * as lazyPurchaseOrdersImports from "./procurement/purchaseOrders/purchaseOrdersImport";
import * as lazyPurchaseOrderssDetailsImports from "./procurement/purchaseOrders/purchaseOrdersDetailsImport";
import * as lazyExchangeGainLoss from "./accounting/exchangeGainLoss/exchangeGainLossImport";
import * as lazyGRNSignatureImports from "./inventory/configuration/grnSignature/grnSignatureImport";
import * as lazyGINSignatureImports from "./inventory/configuration/ginSignature/ginSignatureImport";
import * as lazyConsumableGINImports from "./inventory/consumableItems/gin/ginImport";
import * as lazyConsumableAddGINImports from "./inventory/consumableItems/gin/ginAddImport";
import * as lazyConsumableGINDetailsImports from "./inventory/consumableItems/gin/ginDetailsImport";
import * as lazyExpendableAddGINImports from "./inventory/expendableItem/gin/ginAddImport";
import * as lazyExpendableGINImports from "./inventory/expendableItem/gin/ginImport";
import * as lazyExpendableGINDetailsImports from "./inventory/expendableItem/gin/ginDetailsImport";
import * as lazyNonExpendableGINAddImports from "./inventory/nonExpendableItem/gin/ginAddImport";
import * as lazyNonExpendableGINImports from "./inventory/nonExpendableItem/gin/ginImport";
import * as lazyNonExpendableGINDetailsImports from "./inventory/nonExpendableItem/gin/ginDetailsImport";
import * as lazyVoucherArchiveImports from "./accounting/vouchers_archive/vouchersImport";
import * as lazyVoucherArchiveDetailsImports from "./accounting/vouchers_archive/voucherDetailsImport";
// Import other category-specific imports here

const allLazyImports = {
  ...lazyWelcomeImports,
  ...lazyNotificationImports,
  ...lazyChartOfAccountImports,
  ...lazyVouchersImports,
  ...lazyVoucherDetailsImports,
  ...lazyVoucherAddImports,
  ...lazyJournalImports,
  ...lazyTrailbalanceImports,
  ...lazyLedgerImports,
  ...lazyAccountPayrollImports,
  ...lazyPendingDetailsImports,
  ...lazyProcessingDetailsImports,
  ...lazySettledDetailsImports,
  ...lazyExchangeRateImports,
  ...lazyAccountingRequestImports,
  ...lazyAccountingRequestDetailsImports,
  ...lazyBalanceSheetImports,
  ...lazyIncomeExpenseReportImports,
  ...lazyNotesImports,
  ...lazyExchangeGainLossImports,
  ...lazyFinancialYearImports,
  ...lazyOfficeImports,
  ...lazyCurrencyImports,
  ...lazyBankImports,
  ...lazyBankAccountImports,
  ...lazySupplierImports,
  ...lazyVoucherSignatureImports,
  ...lazyOpportunityImports,
  ...lazyAddOpportunityImports,
  ...lazyEditOpportunityImports,
  ...lazyDetailsOpportunityImports,
  ...lazyProjectManagementImports,
  ...lazyProjectDetailsImports,
  ...lazySectorImports,
  ...lazyDonorsImports,
  ...lazyPeopleTargetedCategoriesImports,
  ...lazyUserImports,
  ...lazyUserRolesImports,
  ...lazyProspectiveEmployeeImports,
  ...lazyProspectiveEmployeeAddImports,
  ...lazyProspectiveEmployeeEditImports,
  ...lazyProspectiveEmployeeAddContractImports,
  ...lazyEmployeeImports,
  ...lazyEmployeeEditImports,
  ...lazyContractAddImports,
  ...lazyContractDetailsImports,
  ...lazyContractEditImports,
  ...lazyTerminateContractImports,
  ...lazyAttendanceImports,
  ...lazyDetailsPendinImports,
  ...lazyDetailsValidatedImports,
  ...lazyPayrollImports,
  ...lazyPayrollPendingImports,
  ...lazyPayrollGeneratedImports,
  ...lazyPayrollExchangeRatesImports,
  ...lazyRecruitmentImports,
  ...lazyRecruitmentAddImports,
  ...lazyRecruitmentDetailsImports,
  ...lazyRecruitmentEditImports,
  ...lazyInterviewFormImports,
  ...lazyDegreeImports,
  ...lazyDesignationImports,
  ...lazyDocumentImports,
  ...lazyEmployeeGradeImports,
  ...lazyProfessionImports,
  ...lazyFieldOfStudyImports,
  ...lazyLeaveImports,
  ...lazyWorkingHoursImports,
  ...lazyCategoryImports,
  ...lazyEmploymentTypeImports,
  ...lazyRequestsImports,
  ...lazyRequestsAddImports,
  ...lazyRequestsDetailsImports,
  ...lazyVendorsImports,
  ...lazyVendorsAddImports,
  ...lazyVendorsDetailsImports,
  ...lazyDocumentTypeImports,
  ...lazVendorsCategoryImports,
  ...lazyMasterStoreItemImports,
  ...lazyConsumableGRNImports,
  ...lazyConsumableAddGRNImports,
  ...lazyConsumableGRNDetailsImports,
  ...lazyStoreItemsImports,
  ...lazyStoreItemsDetailsImports,
  ...lazyExpendableItemMasterStoreItemImports,
  ...lazyExpendableStoreItemNamesImports,
  ...lazyExpendableGRNImports,
  ...lazyExpendableAddGRNImports,
  ...lazyExpendableGRNDetailsImports,
  ...lazyExpendableItemStoreItemImports,
  ...lazyExpendableStoreItemsDetailsImports,
  ...lazyExpendableStoreItemsAddImports,
  ...lazyNonExpendableItemMasterStoreItemImports,
  ...lazyNonExpendableStoreItemNamesImports,
  ...lazyNonExpendableGRNAddImports,
  ...lazyNonExpendableGRNImports,
  ...lazyNonExpendableGRNDetailsImports,
  ...lazyNonExpendableItemStoreItemImports,
  ...lazyNonExpendableStoreItemsAddImports,
  ...lazyNonExpendableStoreItemsDetailsImports,
  ...lazyEmployeesIssuanceRegisterImports,
  ...lazyInventoryRequest,
  ...lazyInventoryRequestDetails,
  ...lazyAssetTypeImports,
  ...lazyDepreciationImports,
  ...lazyReceiptTypeImports,
  ...lazyStatusImports,
  ...lazyLocationImports,
  ...lazyUnitImports,
  ...lazySupplyRawMaterialsImports,
  ...lazySupplyRawMaterialsAddImports,
  ...lazySupplyRawMaterialsDetailsImports,
  ...lazySupplyShippmentsImports,
  ...lazySupplyShippmentsDetailsImports,
  ...lazyItemsImports,
  ...lazyItemsAddImports,
  ...lazyItemsEditImports,
  ...lazyGradeImports,
  ...lazyGradeAddImports,
  ...lazyGradeEditImports,
  ...lazySupplierChainImports,
  ...lazySupplierChainAddImports,
  ...lazySupplierChainEditImports,
  ...lazyItemQrCodeImports,
  ...lazyItemAddQrCodeImports,
  ...lazyItemEditQrCodeImports,
  ...lazyProductDesignImports,
  ...lazyProductDesignAddImports,
  ...lazyProductDesignEditImports,
  ...lazyLanguagesImports,
  ...lazyLanguagesAddImports,
  ...lazyLanguagesEditImports,
  ...lazyPurchaseOrdersImports,
  ...lazyPurchaseOrderssDetailsImports,
  ...lazyStoreItemsDetailsNonExistenceImports,
  ...lazyExchangeGainLoss,
  ...lazyGRNSignatureImports,
  ...lazyGINSignatureImports,
  ...lazyConsumableGINImports,
  ...lazyConsumableAddGINImports,
  ...lazyConsumableGINDetailsImports,
  ...lazyExpendableAddGINImports,
  ...lazyExpendableGINImports,
  ...lazyExpendableGINDetailsImports,
  ...lazyNonExpendableGINAddImports,
  ...lazyNonExpendableGINImports,
  ...lazyNonExpendableGINDetailsImports,
  ...lazyVoucherArchiveImports,
  ...lazyVoucherArchiveDetailsImports,
  // Spread other imports here
};

export default allLazyImports;
