// import * as msal from "@azure/msal-browser";
// import { REDIRECT_URL_LOCAL, REDIRECT_URL_WEB } from "./mainpathforredirect";

// const isLocalEnvironment = window.location.hostname === "localhost";
// const redirectUri = isLocalEnvironment ? REDIRECT_URL_LOCAL : REDIRECT_URL_WEB;

// const authConfig = {
//   auth: {
//     clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
//     redirectUri:`${process.env.REACT_APP_REDIRECT_URI}`,
//     authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
//   },
//   cache: {
//     cacheLocation: "localStorage",
//     storeAuthStateInCookie: true,
//   },
// };

// const msalApp = new msal.PublicClientApplication(authConfig);

// function refreshnewToken() {
//   return new Promise((resolve, reject) => {
//     try {
//       const accounts = msalApp.getAllAccounts();

//       if (accounts.length === 0) {
//         throw new Error("No accounts found");
//       }

//       const silentRequest = {
//         account: accounts[0],
//       };

//       msalApp
//         .acquireTokenSilent(silentRequest)
//         .then((response) => {
//           const idToken = response.idToken;
//           localStorage.removeItem("auth_token");
//           localStorage.setItem("auth_token", response.idToken);
//           resolve(idToken);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

// const isTokenExpired = async (token) => {
//   // Parse the token to get the expiry date
//   token = token ? token : localStorage.getItem("auth_token");

//   const { exp } = JSON.parse(atob(token.split(".")[1]));

//   // Convert the expiry date to a Date object
//   const expiryDate = new Date(exp * 1000);

//   // Get the current date
//   const now = new Date();

//   // Check if the token is expired
//   if (now > expiryDate) {
//     try {
//       await refreshnewToken();
//       window.location.reload();
//     } catch (error) {
//       console.error("Token refresh failed:", error);
//       localStorage.removeItem("auth_token");
//       localStorage.removeItem("office");
//       localStorage.removeItem("id");
//       localStorage.removeItem("email");
//       localStorage.removeItem("name");
//       localStorage.removeItem("access_token");
//       window.location.href = "/auth/login";
//     }
//   }
// };

// export default isTokenExpired;

import * as msal from "@azure/msal-browser";
import { REDIRECT_URL_LOCAL, REDIRECT_URL_WEB } from "./mainpathforredirect";

const isLocalEnvironment = window.location.hostname === "localhost";
const redirectUri = isLocalEnvironment ? REDIRECT_URL_LOCAL : REDIRECT_URL_WEB;

const authConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const msalApp = new msal.PublicClientApplication(authConfig);

async function refreshnewToken() {
  try {
    const accounts = msalApp.getAllAccounts();

    if (accounts.length === 0) {
      throw new Error("No accounts found");
    }

    const silentRequest = {
      account: accounts[0],
    };

    const response = await msalApp.acquireTokenSilent(silentRequest);
    const idToken = response.idToken;

    localStorage.setItem("auth_token", idToken);
    return idToken;
  } catch (error) {
    throw error;
  }
}

const isTokenExpired = async (token) => {
  try {
    // Get token from localStorage if not provided
    token = token || localStorage.getItem("auth_token");
    const id=localStorage.getItem('id');
    const email=localStorage.getItem('email');
    const name=localStorage.getItem('name');
    const permissions=JSON.parse(localStorage.getItem('permissions'))
    if (!permissions || !token || !id || !name || !email) {
      localStorage.clear();
      window.location.reload();
      return null;
    }

    // Decode the token payload to get the expiration time
    const payloadBase64 = token.split(".")[1];
    const decodedPayload = atob(payloadBase64);
    const payload = JSON.parse(decodedPayload);
    const exp = payload.exp;

    // Convert expiration time to Date object
    const expiryDate = new Date(exp * 1000);

    // Get current time
    const now = new Date();

    // Check if the token is expired
    if (now >= expiryDate) {
      // Token is expired; attempt to refresh it
      await refreshnewToken();

      // After refreshing, verify that the new token exists
      const newToken = localStorage.getItem("auth_token");
      if (!newToken) {
        throw new Error("Failed to refresh token");
      }

      // Optionally, verify that the new token is not expired
      const newPayloadBase64 = newToken.split(".")[1];
      const newDecodedPayload = atob(newPayloadBase64);
      const newPayload = JSON.parse(newDecodedPayload);
      const newExp = newPayload.exp;
      const newExpiryDate = new Date(newExp * 1000);
      if (now >= newExpiryDate) {
        throw new Error("New token is expired");
      }
    }

    // Token is valid or has been refreshed successfully
    return;
  } catch (error) {
    console.error("Token validation or refresh failed:", error);
    // Clear user data and redirect to login
    localStorage.clear();
    window.location.href = "/auth/login";
    // Throw error to prevent further execution
    throw error;
  }
};

export default isTokenExpired;
